<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 gray-bg visitor-management-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: routeHelp
        }"
      >
        <template v-slot:left>
          <router-link
            v-if="edit"
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
          <!-- <button @click="edit = false" v-else>
            <icon icon="#cx-hea1-edit-cancel" />
          </button> -->
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "reception-desks",
              "edit-visitor"
            )
          }}
        </div>
        <template v-slot:right>
          <button v-if="!edit" @click="edit = true">
            <icon icon="#cx-hea1-edit" />
          </button>
          <button v-if="edit" @click="submitForm">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>

    <VeeForm
      v-if="visitor"
      id="visitorForm"
      class="form"
      novalidate
      v-slot="{ errors }"
    >
      <ul class="clebex-item-section">
        <!-- email -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.email }"
        >
          <span class="error-message" v-if="errors.email">
            {{ errors.email }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="email">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "email"
                )
              }}</label>
              <Field
                name="email"
                as="input"
                type="text"
                id="email"
                v-model="visitor.email"
                :disabled="true"
              />
            </div>
          </div>
        </li>
        <!-- first name -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.first_name }"
        >
          <span class="error-message" v-if="errors.email">
            {{ errors.first_name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="first_name"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "first-name"
                  )
                }}
                <span>*</span>
              </label>
              <Field
                name="first_name"
                as="input"
                type="text"
                rules="required"
                id="first_name"
                v-model="visitor.first_name"
                :disabled="isDisabled"
              />
            </div>
          </div>
        </li>
        <!-- last name -->
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.first_name }"
        >
          <span class="error-message" v-if="errors.email">
            {{ errors.first_name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="last_name"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "last-name"
                  )
                }}
                <span>*</span>
              </label>
              <Field
                name="last_name"
                as="input"
                type="text"
                rules="required"
                id="last_name"
                v-model="visitor.last_name"
                :disabled="isDisabled"
              />
            </div>
          </div>
        </li>
        <!-- middle name -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.middleName"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="middleNameId">
                {{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "middle-name"
                  )
                }}</label
              >
              <Field
                name="middle_name"
                as="input"
                type="text"
                id="middleNameId"
                v-model="visitor.middle_name"
                :disabled="isDisabled"
              />
            </div>
          </div>
        </li>
        <!-- phone -->
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="phoneId">
                {{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "phone"
                  )
                }}
              </label>
              <Field
                name="phone"
                as="input"
                type="tel"
                id="phoneId"
                autocomplete="off"
                v-model="visitor.phone"
                :disabled="isDisabled"
              />
            </div>
          </div>
        </li>
        <!-- city -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.city"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="cityId">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "city"
                )
              }}</label>
              <Field
                name="city"
                as="input"
                type="text"
                id="cityId"
                v-model="visitor.city"
                :disabled="isDisabled"
              />
            </div>
          </div>
        </li>
        <!-- company -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.company"
        >
          <div class="clebex-section-input">
            <label class="clebex-section-input-label" for="company">{{
              displayLabelName(
                "visitor-management",
                "reception-desks",
                "company"
              )
            }}</label>
            <Field
              name="company"
              as="input"
              type="text"
              rules="required"
              id="company"
              v-model="visitor.company"
              :disabled="isDisabled"
            />
          </div>
        </li>
        <!-- department -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.department"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="departmentId">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "department"
                )
              }}</label>
              <Field
                name="department"
                as="input"
                type="text"
                id="departmentId"
                v-model="visitor.department"
                :disabled="isDisabled"
              />
            </div>
          </div>
        </li>
        <!-- passport -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.passport"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="passportId">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "passport"
                )
              }}</label>
              <Field
                name="passport"
                as="input"
                type="text"
                id="passportId"
                v-model="visitor.passport"
                :disabled="isDisabled"
              />
            </div>
          </div>
        </li>
        <!-- licence plate -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.licensePlate"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="licensePlateId">{{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "license-plate"
                )
              }}</label>
              <Field
                name="license_plate"
                as="input"
                type="text"
                id="licensePlateId"
                v-model="visitor.license_plate"
                :disabled="isDisabled"
              />
            </div>
          </div>
        </li>
        <!-- language -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.language"
        >
          <div
            v-if="edit && visitor.is_visitor"
            @click="openLanguage(language, routeEditVisitorLanguage)"
            class="clebex-item-content-wrapper"
            style="cursor: pointer;"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label class="clebex-section-input-label" style="cursor: pointer;"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "language"
                  )
                }}
                <span>*</span></label
              >
              <p class="text-label ellipsis" style="cursor: pointer;">
                {{ language ? language.name : "" }}
              </p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </div>
          <div class="clebex-section-input" v-else>
            <label class="clebex-section-input-label">{{
              displayLabelName(
                "visitor-management",
                "reception-desks",
                "language"
              )
            }}</label>
            <p class="text-label ellipsis">
              {{ language ? language.name : "" }}
            </p>
          </div>
        </li>
        <!-- assignable containers -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="
            userFormFields.addAssignableContainersToUser &&
              !routeEditVisitorLanguage.includes('my-visitors')
          "
        >
          <router-link
            v-if="edit && visitor.is_visitor"
            :to="{ name: 'r_reception-desk-edit-add-containers-to-visitor' }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedContainers && selectedContainers.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "containers"
                  )
                }}
              </div>
              <p class="text-label">
                {{ selectedContainers.map(item => `${item.name}`).join(", ") }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "containers"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </router-link>
          <div
            class="clebex-section-input"
            v-else-if="selectedContainers && selectedContainers.length"
          >
            <div class="clebex-section-input-label">
              {{
                displayLabelName(
                  "visitor-management",
                  "reception-desks",
                  "containers"
                )
              }}
            </div>
            <p class="text-label">
              {{ selectedContainers.map(item => `${item.name}`).join(", ") }}
            </p>
          </div>

          <div class="clebex-item-content-wrapper" v-else>
            <div class="clebex-section-input">
              <span class="label">
                {{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "containers"
                  )
                }}
              </span>
            </div>
          </div>
        </li>
        <!-- Disability -->
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="disability"
                    type="checkbox"
                    v-model="visitor.disability"
                    :disabled="isDisabled"
                  />
                  <label for="disability"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "disability"
                  )
                }}
              </dd>
            </dl>
            <Field
              name="disability"
              as="input"
              type="hidden"
              :disabled="isDisabled"
              v-model="visitor.disability"
            />
          </div>
        </li>
        <!-- Visits -->
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="userFormFields.language && !edit"
        >
          <router-link
            :to="{ name: routeEditVisitorVisits }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label"
                >{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "visits"
                  )
                }}
                {{ ` (${visitsCount})` }}
              </label>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                ></icon>
              </span>
            </span>
          </router-link>
        </li>
      </ul>
    </VeeForm>
    <template v-slot:footer>
      <nav v-if="visitor" class="actions-menu">
        <ul
          class="actions-list"
          v-if="!visitor.has_bookings && visitor.is_visitor"
        >
          <li class="action">
            <button
              class="action-btn delete-app"
              @click="showDeleteModal = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="showDeleteModal"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("visitor-management.edit-visitor.delete-title") }}
      </h3>
      <p class="text">
        {{
          displayLabelName("visitor-management.edit-visitor.delete-action-text")
        }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import languagesMixin from "@/services/mixins/languages/languages-mixin";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import {
  L_VISITS_FUTURE,
  L_VISITS_TODAY
} from "@/services/consts/modules/visitor-management/visits";

export default {
  name: "EditVisitor",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      showDeleteModal: false,
      edit: true,
      helpSlug: "visitor-edit"
    };
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  watch: {
    visitorId: {
      handler(value) {
        if (typeof value !== "undefined") {
          this.fetchInitData();
        }
      }
    }
  },
  created() {
    this.fetchInitData();
    this.setSelectedLanguage(null);
  },
  computed: {
    ...mapState("visit", ["visits"]),
    ...mapState("user", ["userList", "userFormFields"]),
    ...mapState("visitorManagement", [
      "visitor",
      "selectedContainers",
      "allowedContainers",
      "setRefreshVisitors"
    ]),
    ...mapState("language", ["selectedLanguage"]),
    routeEditVisitorLanguage() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-reception-desk-edit-visitor-select-language";
      }
      return "r_reception-desk-edit-visitor-select-language";
    },
    routeEditVisitorVisits() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-reception-desk-edit-visitor-visits";
      }
      return "r_reception-desk-edit-visitor-visits";
    },
    routeHelp() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-visitor-edit-help-online";
      }
      return "r_visitor-edit-help-online";
    },
    visitorId() {
      const { visitorId } = this.$route.params;
      return visitorId;
    },
    receptionDeskId() {
      const { receptionDeskId } = this.$route.params;
      return receptionDeskId;
    },
    selectedLang() {
      return this.visitor.language ? this.visitor.language.name : "";
    },
    isDisabled() {
      return !this.edit || !this.visitor.is_visitor;
    },
    visitsCount() {
      let todayVisits = 0;
      if (this.visits && this.visits[L_VISITS_TODAY]) {
        todayVisits = this.visits[L_VISITS_TODAY].length;
      }
      let futureVisits = 0;
      if (this.visits && this.visits[L_VISITS_FUTURE]) {
        futureVisits = this.visits[L_VISITS_FUTURE].length;
      }

      return todayVisits + futureVisits;
    },
    language() {
      if (this.selectedLanguage) {
        return this.selectedLanguage;
      } else {
        if (this.visitor) {
          return this.getLanguage(this.visitor.language.id);
        }
        return null;
      }
    }
  },
  methods: {
    ...mapActions("visit", ["getVisits"]),
    ...mapActions("visitorManagement", [
      "getVisitor",
      "putVisitor",
      "deleteVisitor",
      "setSelectedContainer",
      "getAllowedContainers",
      "setSelectedContainers",
      "deleteVisitor"
    ]),
    ...mapActions("language", ["setSelectedLanguage"]),
    getHistoryStatus(id) {
      return this.visitorStatuses.find(status => status.id === id);
    },
    fetchInitData() {
      this.getVisitor({
        visitorId: this.visitorId,
        receptionDeskId: this.receptionDeskId
      }).then(visitor => {
        if (visitor && visitor.assigned_containers) {
          this.setSelectedContainers(visitor.assigned_containers);
        }
      });
      this.getAllowedContainers(this.receptionDeskId);
      this.getVisits({
        payload: {
          visitor_id: this.visitorId,
          reception_desk_id: this.receptionDeskId
        },
        groups: [L_VISITS_TODAY, L_VISITS_FUTURE]
      });
    },
    submitForm() {
      const payload = {
        ...this.visitor,
        language_id: this.language.id,
        reception_desk_id: this.receptionDeskId,
        container_ids: this.selectedContainers
          ? [...this.selectedContainers.map(c => c.id)]
          : []
      };

      this.putVisitor({
        visitorId: this.visitorId,
        params: payload
      }).then(() => {
        this.edit = false;
        this.finish();
      });
    },
    startDelete() {
      this.deleteVisitor(this.visitor.id).then(() => {
        this.showDeleteModal = false;
        this.finish();
      });
    },
    finish() {
      this.$store.commit("visitorManagement/setRefreshVisitors", true, {
        root: true
      });

      this.$router.push({
        name: this.backLinkName,
        params: this.$route.params
      });
    }
  },
  beforeUnmount() {
    this.$store.commit("visitorManagement/setVisitor", null, { root: true });
    this.$store.commit("visitorManagement/setSelectedContainers", null, {
      root: true
    });
  },
  mixins: [languagesMixin, helpOnlineMixin]
};
</script>
